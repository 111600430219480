<template>
  <div id="top-bar-wrapper" class="container-fluid">
    <div class="container" style="background: none">
      <nav
        class="navbar navbar-expand-lg text-white w-100 justify-content-between d-flex"
      >
        <router-link
          :to="{ name: 'home' }"
          id="top-logo"
          class=""
        ></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <!-- <span class="navbar-toggler-icon"></span> -->
          <fa icon="bars" class="text-white" size="lg" />
        </button>
        <div class="collapse navbar-collapse w-auto" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <router-link class="header-link nav-link" :to="{ name: 'home' }">
                الرئيسية
              </router-link>
            </li>
            <li class="nav-item">
              <a
                class="header-link nav-link"
                href="#!"
                @click="showCategoryTap"
              >
                عقارات للإيجار
              </a>
            </li>
            <li class="nav-item">
              <a
                class="header-link nav-link"
                href=""
                @click.prevent="$bvModal.show('request-property-modal')"
              >
                للحجز والطلبات
              </a>
            </li>
            <li class="nav-item">
              <router-link
                class="header-link nav-link"
                :to="{ name: 'leaseRequest' }"
              >
                عقد إلكتروني
              </router-link>
            </li>
            <li class="nav-item dropdown">
              <!-- <a
                class="header-link nav-link"
                :href="`${VUE_APP_USER_URL}/login`"
              >
                الدخول
              </a> -->
              <!-- dropdown menu -->
              <a
                class="header-link nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                الدخول
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a
                  class="dropdown-item"
                  :href="`${VUE_APP_USER_URL}/login`"
                  >العملاء</a
                >
                <a
                  class="dropdown-item"
                  :href="`${VUE_APP_AGENT_URL}/login`"
                  >الموظفون</a
                >
                <a
                  class="dropdown-item"
                  :href="`${VUE_APP_OWNER_URL}/login`"
                  >الملَّاك</a
                >
                <a
                  class="dropdown-item"
                  :href="`${VUE_APP_ADMIN_URL}/login`"
                  >الإدارة</a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>


    <RequestPropertyModal />
  </div>
</template>

<script>
import RequestPropertyModal from "@/components/modals/request-property";
import CategoriesMixin from "@/mixins/categories";

export default {
  mixins: [CategoriesMixin],

  methods: {
    showCategoryTap() {
      this.selectCategory(this.categories[0]);
      this.$router.push({
        name: "category",
        params: { categoryName: this.categories[0].name },
      });
    },
  },

  components: {
    RequestPropertyModal,
  },
};
</script>